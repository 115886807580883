import soledis from './global';

const generateOverlay = (searchBar) => {
  let overlay = document.createElement('div');
  overlay.classList.add('sld-overlay');
  overlay.addEventListener('click', () => {
    searchBar.classList.remove('active');
    overlay.remove();
  });
  document.body.append(overlay);
}

const sldSearchWidgetRemoveClick = () => {
  const searchBar =document.querySelector('.desktop-header-version .search-widget-form');
  const button = document.querySelector('.desktop-header-version .search-widget-button');
  if (searchBar !== null && button !== null) {
    button.addEventListener('click', (e) => {
      let field = document.querySelector('#header .search-widget-field');
      if (!searchBar.classList.contains('active')) {
        e.preventDefault();
        searchBar.classList.add('active');
        field.focus();
        generateOverlay(searchBar);
      }
    });
  }
}

const sldDisplaySearchBar = () => {
  let btnDisplay = document.querySelector('.sld-js-toggle-search-mobile');
  let searchBar = document.querySelector('#header .searchbar-wrapper');
  if (btnDisplay !== null && searchBar !== null) {
      btnDisplay.addEventListener('click', (e) => {
        e.preventDefault();
        if(searchBar.classList.contains('active')) {
          let overlay = document.querySelector('.sld-overlay');
          searchBar.classList.remove('active');
          if (overlay !== null) {
            overlay.remove();
          }
        }
        else {
          searchBar.classList.add('active');
          generateOverlay(searchBar);
          searchBar.querySelector('.search-widget-field').focus();
        }
      });
  }
}

document.addEventListener('DOMContentLoaded', () => {
  // remove comment if you want the animation to display search bar
  sldDisplaySearchBar();
});